import React, { useEffect } from "react"
import { getSearchParams } from "gatsby-query-params"
import Layout from "../Components/Layout"
import Box from "../Components/Box2"
import { RaisedButton } from "../Components/Buttons"
import beginIlliustration from "../assets/images/begin-illiustration.jpg"

export default () => {
  const params = getSearchParams()

  useEffect(() => {
    localStorage.setItem("token", params.t)
  }, [params])

  return (
    <Layout>
      <div className="begin-page">
        <Box>
          <div className="begin-page--intro tac">
            <h1>Nacionalinis aplinkosaugos egzaminas</h1>

            <div className="text-container">
              {/*
              <div className="video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/M4JHSC6Z7RM"
                        frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
              </div>

              <p><b>Mes jau pasiruošę. O tu?</b></p>
              */}

              {/*
              <p>
                <br/> Tarptautinę kovos su klimato kaita dieną paminėjai puikiai – metei sau iššūkį Nacionaliniame aplinkosaugos egzamine.
                <br/> Pasitikrinki, kur suklydai ir kokie atsakymai buvo teisingi.
              </p>


              <p>
                Pradėk egzaminą ir pasitikrink, ką žinai apie aplinkosaugą. Jei nori laimėti prizą, atsakyk teisingai ir išspręsk užduotis kuo greičiau. Sėkmės!
              </p>
              */}

              <p><b>Mes jau pasiruošę. O tu?</b></p>

              <p>
                <br/> Tarptautinę kovos su klimato kaita dieną paminėjai puikiai – metei sau iššūkį Nacionaliniame aplinkosaugos egzamine.
                <br/> Pasitikrinki, kur suklydai ir kokie atsakymai buvo teisingi.
              </p>


            </div>

            <RaisedButton to="/test">Pasitikrinti</RaisedButton>

            {/*
              <div className="text-container">
                <p>
                  Pasiruošęs? <br/>
                  <br/>
                  MOdisėja 2021 jau tuoj prasidės! <br/>
                  <br/>
                  Primename, kad visi klausimai turi tik vieną teisingą atsakymą.
                  <br/>
                  <br/>
                  Rezultatus atsiųsime el. paštu žaidynėms pasibaigus. <br/>
                  6 daugiausiai teisingų atsakymų per trumpiausią laiką surinkę dalyviai – 3 geriausi žaidėjai ir 3
                  efektyviausiai sužaidę moksleiviai – bus apdovanoti specialiais MO prizais.
                  <br/>
                  <br/>
                  Pradėk – sėkmės!
                </p>
              </div>

              <RaisedButton to="/test">Pasitikrinti</RaisedButton>
            */}

            {/*
              <div className="text-container">
                <p>

                  Penki daugiausiai teisingų atsakymų per trumpiausią laiką
                  surinkę dalyviai bus apdovanoti specialiais MO prizais.
                  <br/>
                  <br/>
                  Bet svarbiausia ne laimėti, o dalyvauti! <br/>
                  <br/>

                </p>
              </div>

              <RaisedButton to="/test">Pasitikrinti</RaisedButton>
              */
            }

          </div>
        </Box>
      </div>


      {/*
      <div className="tac">
        <img src={beginIlliustration} alt="" />
      </div>
      */}


    </Layout>
  )
}
