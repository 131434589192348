import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import "./Buttons.scss"

const RaisedButton = ({ children, to, href, variant, ...rest }) => {
  const Element = href ? `a` : to ? Link : `button`

  const props = {}

  if (to) {
    props.to = to
  }
  if (href) {
    props.href = href
  }

  return (
    <Element
      className={cx("raised-button", {
        [`variant-${variant}`]: variant,
      })}
      {...rest}
      {...props}
    >
      {children}
    </Element>
  )
}

const GhostButton = ({ children, to, href, variant, ...rest }) => {
  const Element = href ? `a` : to ? Link : `button`

  const props = {}

  if (to) {
    props.to = to
  }
  if (href) {
    props.href = href
  }

  return (
    <Element
      className={cx("ghost-button", {
        [`variant-${variant}`]: variant,
      })}
      {...rest}
      {...props}
    >
      {children}
    </Element>
  )
}

const GhostButton2 = ({ children, to, href, variant, ...rest }) => {
  const Element = href ? `a` : to ? Link : `button`

  const props = {}

  if (to) {
    props.to = to
  }
  if (href) {
    props.href = href
  }

  return (
    <Element
      className={cx("ghost-button2", {
        [`variant-${variant}`]: variant,
      })}
      {...rest}
      {...props}
    >
      {children}
    </Element>
  )
}

const CleanButton = ({ children, to, rest, variant }) => {
  const Element = to ? Link : `button`

  const props = {}

  if (to) {
    props.to = to
  }

  return (
    <Element className={cx("clean-button")} {...rest} {...props}>
      {children}
    </Element>
  )
}

export { RaisedButton, CleanButton, GhostButton, GhostButton2 }
